<template>
  <div class="wrap" :style="{ '--theme': verConfig.theme || '' }">
    <van-nav-bar style="background-color: var(--navBg);height: calc(50rem / 16);" :border="false" left-arrow
      @click-left="() => { $router.go(-1) }">
      <template #right>
        <Lan />
      </template>
    </van-nav-bar>
    <!-- <div class="title">
      <img
        src="@/assets/Img/login/icon/DL_0005_fanhui.png"
        @click="handleClose"
      />
      <p class="versions">{{ $t('Login.Daftar.title') }}</p>
    </div> -->
    <img class="img_logo" :src="require(`@/assets/ico/${ver}_logo.png`)" alt="">
    <img class="logo" :src="require(`@/assets/ico/${ver}.png`)" />
    <van-form @submit="onSubmit">
      <van-field v-for="(item, index) in formList" v-model="form[item.key]" :key="index" :name="item.key"
        :placeholder="$t(item.label)" :rules="item.rules" @click-right-icon="handleLookPass(item)" class="input_item">
        <span slot="label" v-if="item.isPhone && verConfig.areaCode" style="
            padding-left: 12px;
            padding-right: 12px;
            border-right: 1px solid #999;
            border-left: 1px solid #999;
            margin-left: 10px;
          ">
          <AreaCodePicker v-model="areaCode" />
        </span>
        <i slot="left-icon" class="iconfont leftIcon" :class="item.icon" v-if="item.icon"></i>
        <i slot="right-icon" class="iconfont passwordIcon" :class="item.isPassword
          ? 'icon-yanjing_yincang_o'
          : 'icon-yanjing_xianshi_o'
          " v-if="item.key === 'password' || item.key === 'againPassword'"></i>
        <van-button slot="button" size="mini" v-if="item.key === 'code' && isSendCodeClick" color="var(--theme)"
          :style="{ borderRadius: '5px' }" @click="sendCode">
          {{ $t("Login.Forgot.sendCode") }}
        </van-button>
      </van-field>
      <div style="margin: 16px">
        <van-button round block type="info" native-type="submit" color="var(--theme)">
          {{ $t("Login.Forgot.confirm") }}
        </van-button>
        <van-button round block type="info" plain color="var(--theme)" :style="{ marginTop: 'calc(15rem / 16)' }"
          @click="handleClose">
          {{ $t("Login.Forgot.goBack") }}
        </van-button>
      </div>
    </van-form>
    <LoadingRE :show="LoadingShow" />
  </div>
</template>

<script>
import {
  Row,
  Col,
  Icon,
  Cell,
  GridItem,
  Grid,
  NavBar,
  ActionSheet,
  Button,
  Toast,
  Field,
  Form,
} from "vant";

// 设置vant的多语言
import md5 from "js-md5";
import Cookies from "js-cookie";
import { Locale } from "vant";
import enUS from "vant/lib/locale/lang/en-US";
import Modelu from "@/utils/Module";
import antiShake from "@/utils/anti-shake";
import { getNewPhone } from "@/utils/tools";
import AreaCodePicker from "@/components/areaCodePicker";
import { SEND_SMS_CODE, LOGIN } from "@/api";
import LoadingRE from "@/components/LoadingRE";
import Lan from '@/components/lan';

export default {
  components: {
    [Row.name]: Row,
    [Col.name]: Col,
    [Icon.name]: Icon,
    [Cell.name]: Cell,
    [GridItem.name]: GridItem,
    [Grid.name]: Grid,
    [NavBar.name]: NavBar,
    [ActionSheet.name]: ActionSheet,
    [Button.name]: Button,
    [Form.name]: Form,
    [Field.name]: Field,
    LoadingRE,
    AreaCodePicker,
    Lan
  },
  data() {
    return {
      country: Cookies.get("language"),
      LoadingShow: false,
      areaCode: "",
      form: {
        phone: "",
        userName: "",
        code: "",
        password: "",
        againPassword: "",
        referrer: "",
      },

      isSendCodeClick: true,
      auth_time: 0,
      codeImg: "",
    };
  },
  computed: {
    formList() {
      return [
        {
          label: "Login.Daftar.userName",
          type: "text",
          key: "nickname",
          icon: "icon-cardid",
          length: 99,
          rules: [{ required: true, message: this.$t("hint.inputUserName") }]
        },
        {
          label: "Login.Daftar.phone",
          type: "number",
          key: "userphone",
          isPhone: true,
          length: 99,
          icon: "icon-zhanghu",
          rules: [{ required: true, message: this.$t("hint.inputPhone") }]
        },
        {
          label: "Login.Daftar.password",
          type: "password",
          key: "userpass",
          isPassword: true,
          icon: "icon-jiesuo",
          length: 99,
          rules: [{ required: true, message: this.$t("hint.inputPassword") }]
        },
        {
          label: "Login.Daftar.againPassword",
          type: "password",
          key: "againPassword",
          isPassword: true,
          icon: "icon-jiesuo",
          length: 99,
          rules: [{ required: true, message: this.$t("hint.inputAgainPassword") }]
        },
        {
          label: "Login.Daftar.referrer",
          type: "text",
          key: "fromid",
          icon: "icon-yaoqing",
          length: 99,
          rules: [{ required: true, message: this.$t("hint.inputFromid") }]
        },
      ]
    }
  },
  created() {
    this.setFromId();
  },
  methods: {
    onSubmit(values) {
      // console.log(values);
      const { nickname, fromid, userpass, userphone, againPassword } = values;
      let code = values.code;
      const codeItem = this.formList.find(item => item.key === "code");
      if (!codeItem) {
        code = "112233";
      }
      if (userpass !== againPassword) return Toast(this.$t("Hint.pwdInconformity"));
      this.$store.dispatch("login", {
        nickname,
        userphone,
        userpass,
        fromid,
        code
      })
    },
    /** handle **/
    sendCode: antiShake.debounce(function (e) {
      if (!this.form.phone.length) {
        Toast(this.$t("Hint.phoneNot")); // 手机号/账号不能为空
        return false;
      }
      let form = {
        userphone: this.form.phone,
        must: 1, // this.type === 'reg' ? 1 : 2
      };
      /**
       * TO DO
       * 这个是短信验证码的，现在暂时不用了
       */
      // if (this.form.Area) {
      //   form.phone = `+${this.form.Area}-${this.form.phone}`
      // } else {
      //   form.phone = `${this.form.phone}`
      // }

      this.LoadingShow = true;
      SEND_SMS_CODE(form)
        .then((r) => {
          this.LoadingShow = false;
          if (r.data.ret === 1) {
            // Toast(this.$t('Hint.success'))
            Toast(r.data.msg);
            this.isSendCodeClick = false;
            this.auth_time = 60;
            let auth_timetimer = setInterval(() => {
              this.auth_time--;
              if (this.auth_time <= 0) {
                this.isSendCodeClick = true;
                clearInterval(auth_timetimer);
              }
            }, 1000);
            // this.codeImg = `data:image/jpg;base64,${r.data.notifyList[0].data.code}`
          } else {
            Toast(r.data.msg);
          }
        })
        .catch((e) => {
          this.LoadingShow = false;
          console.log(e);
        });
    }, 200),
    handleClose() {
      this.form = {
        uesrName: "",
        code: "",
        password: "",
        againPassword: "",
      };
      // this.$router.go(-1)
      this.$router.push({ name: "login" });
    },
    handleConfirm() {
      let list = ["phone", "code", "userName", "password", "againPassword"];
      // let list = ['phone', 'userName', 'password', 'againPassword']
      let count = 0;
      list.forEach((_) => {
        if (this.form[_]) {
          count++;
        }
      });
      if (this.form.password !== this.form.againPassword) {
        Toast(this.$t("Hint.pwdInconformity")); // 密码不一致
      } else if (count < 4) {
        Toast(this.$t("Hint.notInput")); // 输入内容不完整
      } else {
        this.register();
      }
    },
    handleLookPass(item) {
      this.formList.forEach((_) => {
        if (item.key === _.key) {
          if (_.type === "password") {
            _.type = "text";
            return;
          }
          _.type = "password";
        }
      });
    },
    /** api **/
    register() {
      let form = { ...this.form };
      form.cmd = "100001";
      form.phone = getNewPhone(this.form.phone);
      // form.phone = this.form.phone
      form.pwd = md5(this.form.password);
      form.nickname = this.form.userName;
      form.version = Modelu.VersionEvent();
      form.introducer = this.form.referrer;
      form.channel = Modelu.signStrFunc();
      delete form.againPassword;
      delete form.password;
      delete form.referrer;
      this.$store.commit("SET_LOGIN_INFO", {
        phone: form.phone,
        nickname: this.form.userName,
        version: Modelu.VersionEvent(),
        introducer: this.form.referrer,
        channel: Modelu.signStrFunc(),
        pwd: form.pwd,
      });
      this.LoadingShow = true;
      let item = {
        userphone: this.form.phone,
        userpass: this.form.password,
        code: this.form.code,
      };
      if (this.$cookie.get("fromid")) {
        item.fromid = this.$cookie.get("fromid");
      }
      LOGIN(item)
        .then((r) => {
          this.LoadingShow = false;
          this.$store.commit("SET_LOGIN_INFO", {
            phone: getNewPhone(this.form.userName),
            pwd: this.form.password,
            channel: Modelu.signStrFunc(),
          });
          if (r.data.ret === 1) {
            this.$cookie.set("userInfo", JSON.stringify(r.data.data));
            this.$cookie.set("token", r.data.data.token);
            if (this.$cookie.get("fromid")) {
              this.$cookie.remove("fromid");
            }
            this.$router.replace("goods");
          } else {
            Toast(r.data.msg);
          }
        })
        .catch((e) => {
          this.LoadingShow = false;
          console.log(e);
        });
    },
    /* 其他 */
    setFromId() {
      let fromid = this.$route.query.id || this.$route.query.fromid;
      if (fromid) {
        this.$cookie.set("fromid", fromid);
      }
    },
  },
};
</script>

<style lang="less" scoped>
.wrap {
  background-color: #000;
  height: 100vh;

  /deep/ .van-nav-bar__content {
    background-color: var(--navBg);
    height: calc(50rem / 16);
  }

  /deep/ .van-nav-bar .van-icon {
    color: #fff;
  }

  /deep/ .van-cell {
    background-color: transparent;
  }

  /deep/ .van-field__control {
    color: #fff;
    background: transparent !important;
  }

  /deep/ .van-field--error {
    color: #fff;
  }
  .img_logo{
    display: block;
    height: calc(40rem /16);
    margin-top: calc(10rem / 16);
    margin-left: calc(16rem / 16);
  }
  .logo {
    display: block;
    min-width: calc(100rem / 16);
    height: calc(100rem / 16);
    margin: calc(60rem / 16) auto calc(20rem / 16);
  }

  .input_item {
    /deep/ .van-field__label {
      width: fit-content;
    }
  }

  .leftIcon {
    font-size: calc(24rem / 16);
    color: #fff;
  }

  .passwordIcon {
    font-size: calc(26rem / 16);
    color: #fff;
  }
}

.title {
  height: calc(45rem / 16);
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: calc(14rem / 16);
  position: relative;

  >img {
    width: calc(18rem / 16);
    height: calc(18rem / 16);
    position: absolute;
    top: 50%;
    left: calc(20rem / 16);
    transform: translate(0, -50%);
    z-index: 9;
  }
}

.cont {
  // width: calc(295rem / 16);
  width: 90%;
  margin: auto;
  box-shadow: 0 0.053333rem 0.533333rem 0.053333rem #dfdfdf;
  padding: calc(10rem / 16);
  border-radius: 6px;
  margin-top: calc(10rem / 16);

  >.form {
    width: 100%;
    height: calc(40rem / 16);
    border-radius: 25px;
    border: calc(1rem / 16) solid #eee;
    display: flex;
    align-items: center;
    // justify-content: space-between;
    margin-bottom: calc(15rem / 16);

    >input {
      padding-left: calc(15rem / 16);
      font-size: calc(14rem / 16);
      border: 0;
    }

    >button {
      // width: calc(77rem / 16);
      height: calc(22rem / 16);
      font-size: calc(12rem / 16);
      color: #fff;
      background: red;
      border-radius: 5px;
      border: 0;
      margin-right: calc(15rem / 16);
    }

    >img {
      width: calc(30rem / 16);
      margin-right: calc(15rem / 16);
    }

    >.code-img {
      width: calc(100rem / 16);
      height: calc(40rem / 16);
    }
  }

  >.hintReference {
    color: #ec0022;
    font-size: calc(14rem / 16);
    margin: 0 calc(10rem / 16) calc(15rem / 16);
  }

  >.btn-box {
    display: flex;
    flex-direction: column;

    >button {
      width: 100%;
      height: calc(40rem / 16);
      border-radius: 25px;
      border: 0;
      font-size: calc(18rem / 16);
    }

    >button:nth-child(1) {
      background: #ec0022;
      color: #fff;
      margin-bottom: calc(15rem / 16);
    }

    >button:nth-last-child(1) {
      background: #fff;
      color: #ec0022;
      border: calc(1rem / 16) solid #ec0022;
    }
  }
}</style>
